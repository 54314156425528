<template>
  <v-navigation-drawer
    v-model="drawer"
    class="sidebar"
    fixed
    right
    hide-overlay
    app
    width="320"
  >
    <div class="content">
      <header class="drawer-header">
        <typography v-if="$vuetify.breakpoint.mdAndUp" type="h5" light
          >You've told us...</typography
        >
        <text-button v-else active color="dark-blue" @click="onOpenSaveAndShare"
          >Save and share</text-button
        >
      </header>
      <expansion-panel v-model="openDrawerPanelIndex">
        <expansion-panel-content
          :expand-icon="
            openDrawerPanelIndex === yourBusinessIndex ? 'remove' : 'add'
          "
        >
          <div slot="header">Your business</div>
          <sidebar-item
            v-if="businessType"
            :to-id="businessTypeId"
            label="Business setup"
            >{{ businessTypeLabel }}</sidebar-item
          >

          <sidebar-item
            v-if="isBusinessUseOnly"
            :to-id="businessUseOnlyId"
            label="Is business use only"
            >{{ isBusinessUseOnly ? 'Yes' : 'No' }}</sidebar-item
          >

          <sidebar-item
            v-if="businessNameComplete && businessName"
            :to-id="businessNameId"
            label="Business name"
            >{{ businessName }}</sidebar-item
          >

          <sidebar-item
            v-if="businessNameComplete && tradingName"
            :to-id="tradingNameId"
            label="Trading name"
            >{{ tradingName }}</sidebar-item
          >

          <sidebar-item
            v-if="physicalAddressComplete && physicalAddressText"
            :to-id="physicalAddressId"
            label="Physical address"
            >{{ physicalAddressText }}</sidebar-item
          >

          <sidebar-item
            v-if="businessSectionComplete && formattedFuelAmount"
            :to-id="fuelAmountId"
            label="Monthly fuel estimate"
            >{{ formattedFuelAmount }}</sidebar-item
          >
        </expansion-panel-content>

        <expansion-panel-content
          :disabled="!businessSectionComplete"
          :expand-icon="
            openDrawerPanelIndex === yourOfferIndex ? 'remove' : 'add'
          "
        >
          <div slot="header">Your offer</div>
          <sidebar-item
            v-if="promoCodeComplete"
            :to-id="offerBannerId"
            label="Promo code"
            >{{
              promoCode && promoCodeValid ? promoCode : 'None'
            }}</sidebar-item
          >
          <sidebar-item
            v-if="membershipNumberRequired && membershipNumber"
            :to-id="membershipNumberId"
            :label="membershipNumberLabel"
            >{{ membershipNumber || 'None' }}</sidebar-item
          >
          <sidebar-item
            v-if="
              pointType === airpointsValue &&
                airpointsNumber &&
                airpointsValid &&
                offerSectionComplete &&
                earnsLoyaltyPoints
            "
            :to-id="loyaltyId"
            :note="`${airpointsFirstName} ${airpointsLastName}`"
            label="Airpoints™"
            >{{ airpointsNumber }}</sidebar-item
          >
          <sidebar-item
            v-else-if="
              pointType === flyBuysValue &&
                flyBuysNumber &&
                offerSectionComplete &&
                earnsLoyaltyPoints
            "
            :to-id="loyaltyId"
            label="Fly Buys"
            >{{ flyBuysNumber }}</sidebar-item
          >
          <sidebar-item
            v-else-if="offerSectionComplete && earnsLoyaltyPoints"
            :to-id="loyaltyId"
            label="Loyalty"
            >None</sidebar-item
          >
        </expansion-panel-content>

        <expansion-panel-content
          :disabled="!offerSectionComplete"
          :expand-icon="
            openDrawerPanelIndex === creditCheckIndex ? 'remove' : 'add'
          "
        >
          <div slot="header">Credit check</div>
          <sidebar-item
            v-if="legalNameComplete"
            :to-id="legalNameId"
            label="Legal name"
            >{{ legalName }}</sidebar-item
          >
          <sidebar-item v-if="gender" :to-id="genderId" label="Gender">{{
            genderLabel
          }}</sidebar-item>
          <sidebar-item
            v-if="dateOfBirthComplete"
            :to-id="dobId"
            label="Date of birth"
            >{{ formattedDateOfBirth }}</sidebar-item
          >
          <sidebar-item
            v-if="driversLicenseComplete"
            :to-id="driversLicenseId"
            :note="driversLicenseVersionNo"
            label="Driver licence"
            >{{ driversLicenseNumber }}</sidebar-item
          >
          <sidebar-item
            v-if="creditSectionComplete"
            :to-id="residentialAddressId"
            label="Residential address"
            >{{ residentialAddressText }}</sidebar-item
          >
        </expansion-panel-content>

        <expansion-panel-content
          :disabled="!creditSectionComplete"
          :expand-icon="
            openDrawerPanelIndex === finalDetailsIndex ? 'remove' : 'add'
          "
        >
          <div slot="header">Final details</div>
          <sidebar-item
            v-if="deliveryAddressComplete"
            :to-id="deliveryAddressId"
            label="Delivery address"
            >{{ deliveryAddressText }}</sidebar-item
          >
          <sidebar-item
            v-if="contactNumberComplete"
            :to-id="contactNumberId"
            label="Contact number"
            >{{ contactNumber }}</sidebar-item
          >
          <sidebar-item
            v-if="invoiceEmailComplete"
            :to-id="invoiceEmailId"
            label="Invoice email"
            >{{ invoiceEmail }}</sidebar-item
          >
          <sidebar-item
            v-if="invoiceDateComplete"
            :to-id="invoiceDateId"
            label="Invoice date"
            >{{ invoiceDateDescription }}</sidebar-item
          >
          <sidebar-item
            v-if="paymentPlan && !skipPaymentPlan"
            :to-id="paymentPlanId"
            label="Account fee payment"
            >{{ paymentPlan }}</sidebar-item
          >
        </expansion-panel-content>
      </expansion-panel>
      <tooltip class="help-tooltip" v-model="help" closeDelay="60000" top>
        <div slot="activator" class="help">
          <icon-button @click="onClickHelp">
            <help-icon />
            <typography type="small-p" class="help__text" no-margin>Need help?</typography>
          </icon-button>
        </div>
          <div @mouseleave="closeHelp">
           <typography type="small-p"  class="help-tooltip__content" no-margin
              >Our team is on hand to help any time between 7am - 9pm weekdays, or 8am - 1pm Saturday.
              Just call us on
              <a href="tel:0800 936 374">0800 Z ENERGY (0800 936 374)</a> or fill in a contact form here
              <a href="https://www.z.co.nz/contact-us/" target="_blank">Contact Z Business</a> and we'll be happy to help.
            </typography>
          </div>
      </tooltip>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import SidebarItem from '@/components/molecules/SidebarItem.vue';
import ExpansionPanel from '@/components/atoms/ExpansionPanel.vue';
import ExpansionPanelContent from '@/components/atoms/ExpansionPanelContent.vue';
import Tooltip from '@/components/atoms/Tooltip.vue';
import TextButton from '@/components/atoms/TextButton.vue';
import IconButton from '@/components/atoms/IconButton.vue';
import HelpIcon from '@/assets/icons/help-white.svg';
import {
  SECTION_YOUR_BUSINESS,
  SECTION_YOUR_OFFER,
  SECTION_CREDIT_CHECK,
  SECTION_FINAL_DETAILS,
  BUSINESS_TYPE,
  BUSINESS_NAME,
  BUSINESS_USE_ONLY,
  TRADING_NAME,
  PHYSICAL_ADDRESS,
  FUEL_AMOUNT,
  OFFER_BANNER,
  MEMBERSHIP_NUMBER,
  LOYALTY,
  LEGAL_NAME,
  GENDER,
  DOB,
  RESIDENTIAL_ADDRESS,
  DRIVERS_LICENSE,
  DELIVERY_ADDRESS,
  CONTACT_NUMBER,
  INVOICE_EMAIL,
  INVOICE_DATE,
  PAYMENT_PLAN,
  BANK_DETAILS
} from '@/constants/html-ids';
import { formatDate } from '@/helpers/date';
import { formatCurrency } from '@/helpers/numeral';
import {
  YOUR_BUSINESS_INDEX,
  YOUR_OFFER_INDEX,
  CREDIT_CHECK_INDEX,
  FINAL_DETAILS_INDEX
} from '@/constants/ui';
import { FLYBUYS, AIRPOINTS, INVOICE_DATE_OPTIONS, TRUST_OR_SOCIETY } from '@/constants/form';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    Typography,
    TextButton,
    Tooltip,
    IconButton,
    HelpIcon,
    SidebarItem,
    ExpansionPanel,
    ExpansionPanelContent
  },
  computed: {
    businessTypeLabel() {
      switch (this.businessType) {
        case 'Limited':
          return 'Limited Company';
        case 'SoleTrader':
          return 'Sole Trader';
        case 'TrustSociety':
          return 'Charity, Trust or Society';
        default:
          return this.businessType;
      }
    },
    genderLabel() {
      switch (this.gender) {
        case 'NonBinary':
          return 'Non binary';
        default:
          return this.gender;
      }
    },
    help: {
      get() {
        return this.$store.state.ui.showHelp;
      },
      set(show) {
        this.$store.dispatch('ui/toggleHelp', show);
      }
    },
    drawer: {
      get() {
        if (this.$vuetify.breakpoint.lgAndUp) {
          return true;
        }

        return this.$store.state.ui.drawerOpen;
      },
      set(isOpen) {
        this.$store.dispatch('ui/toggleDrawer', isOpen);
      }
    },
    openDrawerPanelIndex: {
      get() {
        return this.$store.state.ui.openDrawerPanelIndex;
      },
      set(value) {
        this.$store.dispatch('ui/changeDrawerPanelIndex', value);
      }
    },
    membershipNumberLabel: {
      get() {
        if (this.$store.state.business.businessType === TRUST_OR_SOCIETY) {
          return "Registration number";
        }

        return "Membership number";
      }
    },
    ...mapState({
      businessType: state => state.business.businessType,
      isBusinessUseOnly: state => state.business.fuelCardBusinessUseOnly,
      businessName: state => state.business.businessName,
      tradingName: state => state.business.tradingName,
      physicalAddressText: state => state.business.physicalAddressText,
      physicalAddressComplete: state => state.business.physicalAddressComplete,
      businessNameComplete: state => state.business.businessNameComplete,
      formattedFuelAmount: state => formatCurrency(state.business.fuelAmount),
      businessSectionComplete: state => state.business.sectionComplete,
      offerSectionComplete: state => state.offer.sectionComplete,
      creditSectionComplete: state => state.credit.sectionComplete,
      promoCode: state => state.offer.promoCode,
      promoCodeComplete: state => state.offer.promoCodeComplete,
      airpointsNumber: state => state.offer.airpointsNumber,
      airpointsFirstName: state => state.offer.airpointsFirstName,
      airpointsLastName: state => state.offer.airpointsLastName,
      flyBuysNumber: state => state.offer.flyBuysNumber,
      promoCodeValid: state => state.offer.promoCodeValid,
      airpointsValid: state => state.offer.airpointsValid,
      pointType: state => state.offer.pointType,
      membershipNumber: state => state.offer.membershipNumber,
      legalName: state => state.credit.legalName,
      legalNameComplete: state => state.credit.legalNameComplete,
      gender: state => state.credit.gender,
      formattedDateOfBirth: state => formatDate(state.credit.dateOfBirth),
      dateOfBirthComplete: state => state.credit.dateOfBirthComplete,
      residentialAddress: state => state.credit.residentialAddress,
      residentialAddressText: state => state.credit.residentialAddressText,
      driversLicenseNumber: state => state.credit.driversLicenseNumber,
      driversLicenseVersionNo: state => state.credit.driversLicenseVersionNo,
      driversLicenseComplete: state => state.credit.driversLicenseComplete,
      deliveryAddressText: state => state.finalDetails.deliveryAddressText,
      deliveryAddressComplete: state =>
        state.finalDetails.deliveryAddressComplete,
      contactNumber: state => state.finalDetails.contactNumber,
      contactNumberComplete: state => state.finalDetails.contactNumberComplete,
      invoiceEmail: state => state.finalDetails.invoiceEmail,
      invoiceEmailComplete: state => state.finalDetails.invoiceEmailComplete,
      invoiceDateDescription: state =>
        INVOICE_DATE_OPTIONS[state.finalDetails.invoiceDate],
      invoiceDateComplete: state => state.finalDetails.invoiceDateComplete,
      paymentPlan: state => state.finalDetails.paymentPlan,
      bankName: state => state.finalDetails.bankName,
      bankAccountName: state => state.finalDetails.bankAccountName,
      bankAccountNumber: state => state.finalDetails.bankAccountNumber
    }),
    ...mapGetters({
      currentSection: 'navigation/currentSection',
      earnsLoyaltyPoints: 'offer/earnsLoyaltyPoints',
      membershipNumberRequired: 'offer/membershipNumberRequired',
      skipPaymentPlan: 'offer/skipPaymentPlan'
    }),
    businessTypeId: () => BUSINESS_TYPE,
    businessNameId: () => BUSINESS_NAME,
    businessUseOnlyId: () => BUSINESS_USE_ONLY,
    tradingNameId: () => TRADING_NAME,
    physicalAddressId: () => PHYSICAL_ADDRESS,
    fuelAmountId: () => FUEL_AMOUNT,
    yourBusinessIndex: () => YOUR_BUSINESS_INDEX,
    yourOfferIndex: () => YOUR_OFFER_INDEX,
    creditCheckIndex: () => CREDIT_CHECK_INDEX,
    finalDetailsIndex: () => FINAL_DETAILS_INDEX,
    offerBannerId: () => OFFER_BANNER,
    membershipNumberId: () => MEMBERSHIP_NUMBER,
    loyaltyId: () => LOYALTY,
    flyBuysValue: () => FLYBUYS,
    airpointsValue: () => AIRPOINTS,
    legalNameId: () => LEGAL_NAME,
    genderId: () => GENDER,
    dobId: () => DOB,
    residentialAddressId: () => RESIDENTIAL_ADDRESS,
    driversLicenseId: () => DRIVERS_LICENSE,
    deliveryAddressId: () => DELIVERY_ADDRESS,
    contactNumberId: () => CONTACT_NUMBER,
    invoiceEmailId: () => INVOICE_EMAIL,
    invoiceDateId: () => INVOICE_DATE,
    paymentPlanId: () => PAYMENT_PLAN,
    bankDetailsId: () => BANK_DETAILS,
    yourBusinessSectionId: () => SECTION_YOUR_BUSINESS,
    yourOfferSectionId: () => SECTION_YOUR_OFFER,
    creditCheckSectionId: () => SECTION_CREDIT_CHECK,
    finalDetailsSectionId: () => SECTION_FINAL_DETAILS
  },
  watch: {
    currentSection(newVal) {
      switch (newVal) {
        case SECTION_YOUR_BUSINESS:
          this.$store.dispatch(
            'ui/changeDrawerPanelIndex',
            YOUR_BUSINESS_INDEX
          );
          break;
        case SECTION_YOUR_OFFER:
          this.$store.dispatch('ui/changeDrawerPanelIndex', YOUR_OFFER_INDEX);
          break;
        case SECTION_CREDIT_CHECK:
          this.$store.dispatch('ui/changeDrawerPanelIndex', CREDIT_CHECK_INDEX);
          break;
        case SECTION_FINAL_DETAILS:
          this.$store.dispatch(
            'ui/changeDrawerPanelIndex',
            FINAL_DETAILS_INDEX
          );
          break;
        default:
          break;
      }
    }
  },
  methods: {
    onOpenSaveAndShare() {
      this.$store.dispatch('saver/saveDraft');
      this.$store.dispatch('ui/toggleShareDialog', true);
    },
    onClickHelp() {
      this.$store.dispatch('ui/toggleHelp', !this.$store.state.ui.showHelp);
    },
    closeHelp() {
      this.$store.dispatch('ui/toggleHelp', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.sidebar {
  color: $white;
  max-width: 100%;
  background-image: linear-gradient(-180deg, #f7801d 0%, #f76b1c 100%);
  padding: rem(20px 25px);

  @include md {
    padding: rem(30px 55px);
  }
}

.drawer-header {
  text-align: center;
  margin-bottom: rem(20px);

  @include md {
    margin-bottom: 0;
    text-align: left;
  }
}

.content {
  position: relative;
  padding-bottom: rem(80px);
  min-height: 100%;

  .help {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__text {
      margin-left: rem(10px);
    }
  }
}

.help-tooltip {
  position: absolute;
  left: 0;
  bottom: 0;

  &__content {
    color: $charcoal;
    width: rem(270px);
  }
}
</style>
