// Form sections
export const SECTION_YOUR_BUSINESS = 'your-business';
export const SECTION_YOUR_OFFER = 'your-offer';
export const SECTION_CREDIT_CHECK = 'credit-check-details';
export const SECTION_FINAL_DETAILS = 'final-details';

// Form questions
// landing
export const LANDING_FIELDS = 'landing-fields';

// Your business section
export const BUSINESS_TYPE = 'business-type';
export const BUSINESS_USE_ONLY = 'business-use-only';
export const BUSINESS_NAME = 'business-name';
export const TRADING_NAME = 'trading-name';
export const PHYSICAL_ADDRESS = 'physical-address';
export const FUEL_AMOUNT = 'fuel-amount';
export const SPECIAL_RATES_FORM = 'special-rates';

// Your offer section
export const OFFER_BANNER = 'offer-banner';
export const LOYALTY = 'loyalty';
export const PROMO_CODE = 'promo-code';
export const MEMBERSHIP_NUMBER = 'membership-number';
export const FLY_BUYS_ID = 'flybuys';
export const AIRPOINTS_ID = 'airpoints';
export const PUMPED_OFFER_ID = 'pumped-offer';

// Credit check section
export const IS_COMPANY_DIRECTOR = 'is-company-director';
export const REPRESENTATIVE_REQUIREMENTS = 'representative-requirements';
export const LEGAL_NAME = 'legal-name';
export const GENDER = 'gender';
export const DOB = 'dob';
export const RESIDENTIAL_ADDRESS = 'residential-address';
export const DRIVERS_LICENSE = 'drivers-license';

// final details section
export const DELIVERY_ADDRESS = 'delivery-address';
export const CONTACT_NUMBER = 'contact-number';
export const INVOICE_EMAIL = 'invoice-email';
export const INVOICE_DATE = 'invoice-date';
export const PAYMENT_PLAN = 'payment-plan';
export const BANK_DETAILS = 'bank-details';
export const SUBMIT_FORM = 'submit-form';

export const NEXT_STEPS = 'next-steps';

// Card preferences
export const DAILY_LIMIT = 'daily-limit';
export const MONTHLY_LIMIT = 'monthly-limit';
export const VEHICLE_REGISTRATION = 'vehicle-registration';
export const DRIVER_NAME = 'driver-name';

// How to identify fields with an error
export const FIELD_ERROR_SELECTOR = 'div.error--text';
