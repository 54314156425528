import api from '@/api';
import { LOAD_OFFER_ERROR } from '@/constants/errors';

export const state = () => ({
  promoCode: '',
  promoOfferId: null,
  isDiscountOffPump: true,
  standardOfferId: null,
  pointType: null,
  flyBuysNumber: null,
  flyBuysValid: false,
  flyBuysValidated: null,
  airpointsNumber: null,
  airpointsFirstName: '',
  airpointsLastName: '',
  airpointsValid: false,
  airpointsValidated: null,
  promoCodeValid: true,
  promoCodeComplete: false,
  promoOfferDialogOpen: false,
  sectionComplete: false,
  standardOffer: null,
  promoOffer: null,
  membershipNumber: null,
  validToDate: null
});

export const mutations = {
  changePromoOfferId(state, value) {
    state.promoOfferId = value;
  },
  changeIsDiscountOffPump(state, value) {
    state.isDiscountOffPump = value;
  },
  changeStandardOfferId(state, value) {
    state.standardOfferId = value;
  },
  changeStandardOffer(state, value) {
    state.standardOffer = value;
  },
  changePromoOffer(state, value) {
    state.promoOffer = value;
  },
  changePromoCode(state, value) {
    state.promoCode = value;
  },
  changeMembershipNumber(state, value) {
    state.membershipNumber = value;
  },
  changeValidToDate(state, value) {
    state.validToDate = value;
  },
  changePointType(state, value) {
    state.pointType = value;
  },
  changeFlyBuysNumber(state, value) {
    state.flyBuysNumber = value;
  },
  changeAirpointsNumber(state, value) {
    state.airpointsNumber = value;
  },
  changeAirpointsFirstName(state, value) {
    state.airpointsFirstName = value;
  },
  changeAirpointsLastName(state, value) {
    state.airpointsLastName = value;
  },
  changePromoCodeComplete(state, value) {
    state.promoCodeComplete = value;
  },
  changePromoOfferDialogOpen(state, value) {
    state.promoOfferDialogOpen = value;
  },
  changePromoCodeValid(state, value) {
    state.promoCodeValid = value;
  },
  changeAirpointsValid(state, value) {
    state.airpointsValid = value;
  },
  changeAirpointsValidated(state, value) {
    state.airpointsValidated = value;
  },
  changeFlyBuysValid(state, value) {
    state.flyBuysValid = value;
  },
  changeFlyBuysValidated(state, value) {
    state.flyBuysValidated = value;
  },
  changeSectionComplete(state, value) {
    state.sectionComplete = value;
  }
};

export const actions = {
  async changeStandardOffer({ commit, dispatch }, value) {
    try {
      const response = await api.getStandardOffer(value.fuelAmount);
      commit('changeStandardOffer', response.data);
      commit('changeStandardOfferId', response.data.id);
    } catch (error) {
      if (!error.response) {
        dispatch(
          'ui/addErrorToast',
          {
            id: 'failed-retrieve-standard-offer',
            text: LOAD_OFFER_ERROR
          },
          { root: true }
        );
      }
    }
  },
  changePromoOffer({ commit }, value) {
    commit('changePromoOffer', value);
    commit('changePromoOfferId', value ? value.id : null);
    commit('changeValidToDate', value ? value.validToDate : null);
    commit(
      'changeIsDiscountOffPump',
      value ? value.isDiscountOffPump : state().isDiscountOffPump
    );
  },
  changePromoCode({ commit }, value) {
    commit('changePromoCode', value);
  },
  changeMembershipNumber({ commit }, value) {
    commit('changeMembershipNumber', value);
  },
  changeValidToDate({ commit }, value) {
    commit('changeValidToDate', value);
  },
  changePointType({ commit }, value) {
    commit('changePointType', value);
  },
  changeFlyBuysNumber({ commit }, value) {
    commit('changeFlyBuysNumber', value);
  },
  changeAirpointsNumber({ commit }, value) {
    commit('changeAirpointsNumber', value);
  },
  changeAirpointsFirstName({ commit }, value) {
    commit('changeAirpointsFirstName', value);
  },
  changeAirpointsLastName({ commit }, value) {
    commit('changeAirpointsLastName', value);
  },
  changePromoCodeComplete({ commit }, value) {
    commit('changePromoCodeComplete', value);
  },
  togglePromoOfferDialogOpen({ commit }, value) {
    commit('changePromoOfferDialogOpen', value);
  },
  changeFlyBuysValid({ commit }, value) {
    commit('changeFlyBuysValid', value);
  },
  changeFlyBuysValidated({ commit }, value) {
    commit('changeFlyBuysValidated', value);
  },
  changeAirpointsValid({ commit }, value) {
    commit('changeAirpointsValid', value);
  },
  changeAirpointsValidated({ commit }, value) {
    commit('changeAirpointsValidated', value);
  },
  changePromoCodeValid({ commit }, value) {
    commit('changePromoCodeValid', value);
  },
  changeSectionComplete({ commit, dispatch }, value) {
    if (value) {
      // Prepopulate residential address
      dispatch('credit/prepopulateResidentialAddress', null, { root: true });
    }

    commit('changeSectionComplete', value);
  },
  changeIsDiscountOffPump({ commit }, value) {
    commit('changeIsDiscountOffPump', value);
  },
  async getOfferByPromoCode({ commit, dispatch }, value) {
    try {
      const response = await api.getOfferByPromoCode(value.promoCode);

      const isValid = response.status == 200;
      commit('changePromoCodeValid', isValid);
      commit('changePromoOfferDialogOpen', isValid);

      if (isValid) {
        commit('changePromoCode', value.promoCode);
        commit('changePromoOffer', response.data);
        commit('changePromoOfferId', response.data.id);
        commit('changeIsDiscountOffPump', response.data.isDiscountOffPump);
        commit('changeValidToDate', response.data.validToDate);
      } else {
        commit('changeIsDiscountOffPump', state().isDiscountOffPump);
      }
    } catch (error) {
      commit('changePromoCodeValid', false);
      commit('changePromoOfferDialogOpen', false);
      commit('changeIsDiscountOffPump', state().isDiscountOffPump);
      if (!error.response) {
        dispatch(
          'ui/addErrorToast',
          {
            id: 'failed-retrieve-promo-offer',
            text: LOAD_OFFER_ERROR
          },
          { root: true }
        );
      }
    }
  }
};

export const getters = {
  offerDetails: state => {
    return state.promoOffer || state.standardOffer;
  },
  offerDiscounts: (state, getters) => {
    if (getters.offerDetails === null) return null;

    return getters.offerDetails.discounts;
  },
  earnsLoyaltyPoints: state => {
    return state.promoOffer ? state.promoOffer.earnsLoyaltyPoints : true;
  },
  membershipNumberRequired: state => {
    return state.promoOffer ? state.promoOffer.membershipNoRequired : false;
  },
  skipPaymentPlan: state => {
    return state.promoOffer ? state.promoOffer.skipPaymentPlan : true;
  },
  skipInvoiceDate: state => {
    return state.promoOffer ? state.promoOffer.skipInvoiceDate : true;
  },
  skipCreditCheck: state => {
    return state.promoOffer ? state.promoOffer.skipCreditCheck : false;
  }
};
