import axios from 'axios';
import { API_BASE_URL } from '@/helpers/config';
const http = axios.create({
  baseURL: API_BASE_URL
});

export default {
  // TODO - Replace with strongly typed methods
  post: (url, data, config) => http.post(url, data, config),

  /** @description Saves our initial application in t
   * @param {string} fullName. The applicants name
   * @param {string} email. The applicants email
   * @param {boolean} receiveCorrespondence. Does the applicant wish to receive correspondence.
   * @return {AxiosPromise<any>}
   */
  createApplication: ({ fullName, email, receiveCorrespondence, type }) =>
    http.post('/v1/applications', {
      fullName,
      email,
      receiveCorrespondence,
      type
    }),
  /**
   * @description Save the Vuex RootState to the API as draft.
   * @param {string} application Id
   * @param {object} Vuex rootState object
   * @return {AxiosPromise<any>}
   */
  saveDraft: (applicationId, rootState) => {
    return http.post('/v1/drafts', {
      applicationExternalId: applicationId,
      state: { rootState }
    });
  },
  loadSavedForm: (applicationId, draftId) =>
    http.get(`/v1/applications/${applicationId}/drafts/${draftId}`),
  /** @description Searches for addresses that match the specified query parameter.
   * @param {string} query The search query.
   * @return {AxiosPromise<any>}
   */
  searchAddresses: query =>
    http.get('/v1/addresses', {
      params: {
        query
      }
    }),

  getAddress: dpid => http.get(`/v1/addresses/${dpid}`),

  /** @description Searches for businesses that match the specified query parameter.
   * @param {string} name The name of the business.
   * @param {string} type The type of business.
   * @return {AxiosPromise<any>}
   */
  searchBusinesses: (name, type) =>
    http.get('/v1/businesses', {
      params: {
        name,
        type
      }
    }),

  /** @description Get business details using the nzbn query parameter.
   * @param {string} nzbn The NZ business number.
   * @return {AxiosPromise<any>}
   */
  getBusiness: nzbn => http.get(`/v1/businesses/${nzbn}`),

  /** @description Validates a Fly Buys number.
   * @param {string} flyBuysNumber The Fly Buys number to validate.
   * @return {AxiosPromise<any>}
   */
  validateFlyBuys: flyBuysNumber =>
    http.post('/v1/flybuys/validate', {
      flyBuysNumber
    }),

  /** @description Validates Airpoints membership details.
   * @param {string} membershipNumber The airpoint membership number to validate.
   * @param {string} firstName The member's first name.
   * @param {string} familyName The member's surname.
   * @return {AxiosPromise<any>}
   */
  validateAirpoints: (membershipNumber, firstName, familyName) =>
    http.post('/v1/airpoints/membershipnumber/validate', {
      membershipNumber,
      firstName,
      familyName
    }),

  /** @description Gets an offer by promo code.
   * @param {string} promoCode The promo code for the offer.
   * @return {AxiosPromise<any>}
   */
  getOfferByPromoCode: promoCode =>
    http.get(`/v1/promoOffers/redeem?code=${promoCode}`),

  getStandardOffer: spendLimit =>
    http.get(`/v1/standardOffers/redeem?amount=${spendLimit}`),

  getBanks: () => http.get('/v1/banks'),

  validateBankAccountNumber: (bankId, accountNumber) =>
    http.post('/v1/banks/account/validate', {
      bankId,
      fullAccountNumber: accountNumber
    }),

  submitApplication: (applicationId, application) => {
    // Converting NonBinary to Undisclosed here since its easier
    // than updating the backend/credit check and we're not to
    // concerned about keeping this information
    if (application.creditDetails.gender === 'NonBinary') {
      application.creditDetails.gender = 'Undisclosed';
    }

    return http.put(`/v1/applications/${applicationId}`, {
      details: application
    })
  },

  submitRequestCallback: (applicationId, application) =>
    http.put(`/v1/applications/${applicationId}/requestCallback`, {
      details: application
    }),

  getCreditCheckDecision: applicationId =>
    http.get(`/v1/creditchecks/${applicationId}`),

  submitPersonalGuarantee: (applicationId, personalGuarantee) =>
    http.put(`/v1/applications/${applicationId}/personalGuarantee`, {
      details: personalGuarantee
    }),

  submitCardPreferences: (applicationId, cards) =>
    http.put(`/v1/applications/${applicationId}/card`, {
      preferences: {
        cards: cards
      }
    }),

  shareApplication: (applicationId, emails) =>
    http.post(`/v1/applications/${applicationId}/share`, {
      emails
    })
};
